<template>
  <div>
    <nav-bar headerTitle="会员案例" ref="head" :show-back="true" :header-back="newAppBack" />
    <div :class="['filter f24 row-between-center', appTypeStr === 'mini' ? 'mini' : '']">
      <div class="menu-box row-start-center">
        <div v-for="(item, index) in filterList" :class="{ active: filterIndex === index && showFilter }" :key="index" @click="openFilter(index)" class="menu-item row-start-center">
          <p :class="['ellipsis', item.selectName ? 'fw6' : '']">{{ item.selectName || item.name }}</p>
          <van-icon class="menu-arrow" size="10" name="arrow-down" />
        </div>
      </div>

      <div class="popup-box" :class="{ active: showFilter }">
        <i></i>
        <div class="col-between-start filter-box">
          <div class="filter-list f24">
            <h4>选择{{ filterList[filterIndex].name }}</h4>
            <div class="row-start-center filter-item">
              <div @click="selectFilter(index)" :class="['row-center-center', item.active ? 'active fw6' : '']" v-for="(item, index) in filterList[filterIndex].item" :key="item.type">
                <span class="ellipsis">{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="filter-btn row-between-center f24 fw6">
            <div @click="submitFilter(true)" class="row-center-center">重置</div>
            <div @click="submitFilter()" class="row-center-center active">确定</div>
          </div>
        </div>
      </div>
    </div>

    <div class="container col-start-center">
      <van-list v-model="loading" :finished="finished" @load="getList">
        <div class="case-box">
          <img @click="preview(item)" v-for="item in records" :key="item.casePhotoId" :src="item.casePhoto" alt="" class="case" />
        </div>
      </van-list>

      <div class="empty col-start-center" v-if="records.length === 0">
        <img src="https://img.chaolu.com.cn/ACT/mini-records/empty.png" alt="" />
        <p class="f26">暂无案例</p>
      </div>
    </div>

    <van-overlay :show="showFilter" @click="showFilter = false" />

  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar-202210/index'
import { newAppBack, initBack, appOpenWeb } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'

export default {
  mixins: [userMixin],
  components: {
    navBar,
  },
  data() {
    return {
      filterList: [
        { name: '性别', item: [{name: '男', type: 1}, {name: '女', type: 0}]},
        { name: '运动时长', item: [{name: '1-2个月', type: '1-2个月'},  {name: '3-4个月', type: '3-4个月'}, {name: '5-6个月', type: '5-6个月'}, {name: '7-12个月', type: '7-12个月'}, {name: '12个月以上', type: '12个月以上'}]}, // 直接传中文
        { name: '案例类型', item: [{name: '15分钟体态改善', type: 1}, {name: '长期案例', type: 2}, {name: '灵活度改善', type: 3}]},
      ],
      filterIndex: 0,
      curFilter: {},
      records: [], // 训记列表
      searchForm: {
        page: 1,
        sexList: [],
        durationList: [], // 运动时长
        typeList: [], // 案例类型
        size: 10,
        teacherId: '',
        venueId: '',
      },
      showFilter: false,
      loading: true,
      finished: false,
    }
  },
  computed: {
    subfilterList() {
      if (!this.curFilter) return []
      let columns = []
      if (Array.isArray(this.curFilter)) {
        columns = this.curFilter
      } else {
        console.log('sssdkddk')

        Object.entries(this.curFilter).forEach(([key, value]) => {
          columns.push({ text: value, value: key })
        })
      }
      return columns
    },
  },
  async created() {
    // 初始化
    await this.$getAllInfo()
    initBack()

    this.searchForm.venueId = getParam().venueId
    this.searchForm.teacherId = getParam().teacherId
    this.loading = false
  },

  methods: {
    newAppBack,
    getList() {
      // 由于服务端不方便改造teacherusercase/case/page  教练、门店案例都使用门店案例的接口 教练案例接口这个页面不用
      this.getVenueList()
    },

    getVenueList() {
      this.loading = true
      this.$axios.post(this.baseURLApp + '/teacherusercase/pageByVenue', this.searchForm).then((res) => {
        if (!res.data) {
          this.finished = true
          return
        }
        this.records = this.records.concat(res.data)
        if (res.data.length < this.searchForm.size) {
          this.finished = true
        }
        this.searchForm.page++
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    openFilter(v) {
      this.filterIndex = v
      this.showFilter = true
    },
    selectFilter(index) {
      this.$set(this.filterList[this.filterIndex].item[index], 'active', !this.filterList[this.filterIndex].item[index].active)
      this.filterList[this.filterIndex].selectName = ''
      this.filterList[this.filterIndex].item.forEach((item) => {
        if (item.active) {
          let name = this.filterList[this.filterIndex].selectName || ''
          this.$set(this.filterList[this.filterIndex], 'selectName', name + (name ? `/${item.name}` : `${item.name}`))
        }
      })
    },
    submitFilter(reset) {
      if (reset) {
        this.filterList[this.filterIndex].selectName = ''
        this.filterList[this.filterIndex].item.forEach((item) => {
          this.$set(item, 'active', false)
        })
      } else {
        this.dealForm()
        this.showFilter = false
        this.records = []
        this.searchForm.page = 1
        this.getList()
        window.scrollTo(0, 0)
      }
    },
    dealForm() {
      const freezeKey = ['sexList', 'durationList', 'typeList']
      freezeKey.map((key, index) => {
        this.searchForm[key] = this.filterList[index].item.filter((v) => v.active).map((v) => v.type)
      })
    },

    // reset() {
    //   this.searchForm.page = 1
    //   this.finished = false
    //   this.loading = true
    //   this.records = []
    //   this.getList()
    //   window.scrollTo(0, 0)
    // },
    preview(item) {
      appOpenWeb('案例详情', `${window.location.origin}/#/user-case-detail?casePhotoId=${item.casePhotoId}&caseUserId=${item.userId}&caseTeacherId=${item.teacherId}`)
    },
  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
* {
  box-sizing: border-box;
}
.filter {
  position: sticky;
  top: calc(99px + env(safe-area-inset-top));
  background-color: #fff;
  height: 82px;
  z-index: 100;
  padding: 0 20px;
  &.mini {
    top: 0;
  }
  .menu-box {
    .menu-item {
      height: 48px;
      background: #F5F5F5;
      border-radius: 4px;
      padding: 0 17px;
      margin: 0 16px 0 0;
      & > p {
        max-width: 150px;
      }
      .menu-arrow {
        margin-left: 10px;
        transition: transform 0.3s;
        transform: rotateZ(0deg);
      }
      &.active {
        .menu-arrow {
          transform: rotateZ(180deg);
        }
      }
    }
  }
  .popup-box {
    position: absolute;
    box-sizing: border-box;
    top: 82px;
    left: 0;
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    height: 0;
    transition: height 0.4s;
    z-index: 99;
    & > i {
      width: 100%;
      height: 1px;
      background-color: #eee;
      display: block;
      margin-bottom: 12px;
    }
    &.active {
      height: 310px;
    }
    .filter-box {
      height: 300px;
      padding: 12px 0 20px;
    }
    .filter-list {
      width: 100%;
      background: #ffffff;
      padding: 0 20px;
      //font-size: 18px;
      color: #242831;
      h5 {
        padding: 12px 0 2px;
      }
      .filter-item {
        max-height: 195px;
        overflow-y: auto;
        flex-wrap: wrap;
        div {
          width: 162px;
          height: 48px;
          border: 1px solid #eee;
          border-radius: 4px;
          margin: 18px 20px 0 0;
          flex-shrink: 0;
          padding: 0 8px;
          background-color: #f9fafb;
          &.active {
            background: #ffde00;
            border-color: #ffde00;
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
    .filter-btn {
      padding: 0 20px;
      div {
        width: 346px;
        height: 55px;
        border-radius: 6px;
        border: 1px solid #9aa1a9;
        &.active {
          border-color: #ffde00;
          background: #ffde00;
        }
      }
    }
  }
}
// .filter {
//   padding: 24px 32px;
//   position: sticky;
//   top: calc(99px + env(safe-area-inset-top));
//   background-color: #fff;
// }
// .filtermini{
//     top: 0;
// }

// .filter-box {
//   .filter-item {
//     padding: 0 17px;
//     height: 52px;
//     background: #F5F5F5;
//     border-radius: 4px;
//     margin: 0 12px 0 0;

//     &.active {

//       background: #242831;
//       color: #fff;
//     }
//   }
// }

.container {
  min-height: 100vh;
  width: 100%;
  padding: 25px 24px calc(24px + env(safe-area-inset-bottom));

  .case-box {
    width: 702px;
    display: flex;
    flex-wrap: wrap;
  }

  .case {
    width: 345px;
    margin: 0 0 12px;
    object-fit: cover;
    border-radius: 4px;
  }
  .case:nth-child(2n) {
    margin-left: 12px;
  }

  .empty {
    margin: 200px auto;

    img {
      width: 320px;
      margin: 0 0 12px;
    }

    p {
      color: #6c727a;
    }
  }
}
</style>
