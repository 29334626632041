<template>
  <div>
    <nav-bar headerTitle="案例详情" ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container">
      <div class="block row-between-center">
        <p class="f32 fw6">{{ caseObj.projectName }}</p>
        <p v-if="caseObj.duration" class="f26">
          锻炼时长：<span class="fw6">{{ caseObj.duration }}</span>
        </p>
      </div>
      <img @click="preview(caseObj.encryptImage)" class="case-img flex" :src="caseObj.encryptImage" alt="" />
      <div class="block" v-if="caseObj.indicatorList">
        <h2 class="title f30 fw6">身体数据变化（{{ caseObj.duration }}）</h2>
        <div class="table-box f24">
          <div class="table-head row-start-center">
            <span class="s1 row-start-center flex-auto">数据类型</span>
            <span class="s2 row-center-center">{{ caseObj.startDate }}</span>
            <span class="s3 row-center-center">{{ caseObj.endDate }}</span>
            <span :class="['s4', 'row-center-center', 'flex-auto']">变化</span>
          </div>
          <div class="table-item row-start-center" v-for="item in caseObj.indicatorList" :key="item.name">
            <span class="s1 row-start-center flex-auto">{{ item.name }}({{ item.unit }})</span>
            <span class="s2 row-center-center">{{ item.firstValue }}</span>
            <span class="s3 row-center-center">{{ item.lastValue }}</span>
            <span class="s4 row-center-center flex-auto">
              <img :src="`https://img.chaolu.com.cn/ACT/icon/202412${item.isIncrease ? 'up' : 'down'}.png`" alt="">
              {{ item.change }}
            </span>
          </div>
        </div>
      </div>

      <div v-if="comment.length" class="block">
        <h2 class="title f30 fw6">会员评价</h2>
        <div class="evaluation" v-for="item in comment" :key="item.commentId">
          <div class="time f22 fw6">评价于{{ item.commentTime }}</div>
          <div class="star row-start-center">
            <img v-for="star, index in 5" :key="star"
              :src="`https://img.chaolu.com.cn/MINI/icon/star_${item.score > index ? 'yellow' : 'gary'}.png`" />
          </div>
          <div class="evaluation-content f26">
            {{ item.content }}
          </div>
          <div class="evaluation-img row-start-center" v-if="item.imgs.length">
            <img @click="preview(item.imgs)" v-for="item in item.imgs" :src="item.img" :key="item" />
          </div>
        </div>
      </div>
    </div>
    <div class="button-box f32 row-between-center fw6">
      <!-- <div @click="focus" :class="['button', 'row-center-center']">关注教练</div> -->
      <div @click="appointment" :class="['button', 'row-center-center flex-auto']">约TA课</div>
    </div>

    <van-image-preview v-model="isPreviewShow" :images="previews"></van-image-preview>
  </div>
</template>
<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar-202210/index'
import { initBack, newAppBack, universalPushVC } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'

export default {
  mixins: [userMixin],
  components: {
    navBar,
  },
  data() {
    return {
      caseId: '',
      caseTeacherId: '',
      caseUserId: '',
      caseObj: {},
      comment: [],
      isPreviewShow: false,
      previews: [],
    }
  },
  async created() {
    // 初始化
    await this.$getAllInfo()
    initBack()

    this.caseId = getParam().casePhotoId
    this.caseTeacherId = getParam().caseTeacherId
    this.caseUserId = getParam().caseUserId

    this.getDetail()
    setTimeout(() => {
      this.getComment()
    }, 0);
  },
  methods: {
    newAppBack,
    getDetail() {
      this.$axios.post(`${this.baseURLApp}/teacherusercase/getCaseDetail`, { caseId: this.caseId }).then((res) => {
        this.caseObj = res.data
      })
    },
    getComment() {
      this.$axios
        .post(`${this.baseURLApp}/app/comment/teacherPage`, {
          pages: 1,
          size: 10,
          type: 0, // 固定 0全部
          teacherId: this.caseTeacherId,
          userId: this.caseUserId
        })
        .then((res) => {
          this.comment = res.data.records
        })
    },
    // focus() {
    //   this.$axios
    //     .post(`${this.baseURLApp}/activityShopWeb/querySportsPlanningShop`, {
    //       userId: this.userId,
    //       teacherId: this.detail.createTeacherId,
    //     })
    //     .then((res) => {})
    //     .catch((r) => {})
    // },
    appointment() {
      universalPushVC(51, this.caseTeacherId, 1, 1)
    },
    preview(val) {
      const arr = Array.isArray(val) ? val : [val]
      console.log(arr);

      this.previews = arr
      this.isPreviewShow = true
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  background-color: #f5f5f5;
  padding: 24px;
  min-height: 90vh;

  * {
    box-sizing: border-box;
  }

  .case-img {
    width: 100%;
    margin: 0 0 24px;
  }

  .block {
    width: 100%;
    margin: 0 0 24px;
    padding: 32px;
    border-radius: 20px;
    background-color: #fff;

    .title {
      line-height: 1;
      margin: 0 0 28px;
    }

    .table-box {
      border-radius: 12px;
      overflow: hidden;
      border: 1px solid #eeeeee;

      .table-head {
        background: #f2f7ff;
        height: 88px;
      }

      .table-item {
        height: 88px;
      }

      .s1 {
        width: 160px;
        padding: 0 0 0 20px;
      }

      .s2,
      .s3 {
        width: 175px;
      }

      .s4 {
        width: 130px;

        img {
          width: 20px;
          margin: 0 6px 0 0;
        }
      }

      .s1,
      .s2,
      .s3 {
        height: 100%;
        border-right: 1px solid #eeeeee;
      }

      .table-item:nth-child(odd) {
        background-color: #f9fafb;
      }
    }

    .evaluation {
      margin: 0 0 28px;

      &:last-child {
        margin: 0;
      }

      .star {
        margin: 10px 0 0;

        img {
          width: 24px;
          margin: 0 4px 0 0;
        }
      }

      .evaluation-content {
        margin: 20px 0 0;
      }

      .evaluation-img {
        margin: 10px 0 0;

        img {
          width: 190px;
          height: 190px;
          border-radius: 4px;
          object-fit: cover;
          margin: 0 10px 10px 0;

          &:nth-child(3n) {
            margin: 0 0 10px 0;
          }
        }
      }
    }
  }
}

.button-box {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  color: #242831;
  position: sticky;
  bottom: 0;
  padding: 20px 32px calc(20px + env(safe-area-inset-bottom));
  border-top: 1px solid #ebebeb;
  position: sticky;
  bottom: 0;
  left: 0;

  .button {
    width: 330px;
    height: 92px;
    background: #ffde00;
    border-radius: 8px;
  }
}

/deep/.van-image-preview__overlay {
  background-color: #000;
}</style>
